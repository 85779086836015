
import {defineComponent, ref, onMounted} from "vue";
import {NewUser} from "@/Types/User/NewUser";
import {UserService} from "@/Services/UserService";
import Toast from "@/components/App/Toast.vue";
import {useRouter} from "vue-router";
import {LocalStorageHelper} from "@/Services/LocalStorageHelper";

export default defineComponent({
  name: "UserForm",
  components: {Toast},
  setup() {
    const newUser = ref<NewUser>(new NewUser)
    const passwordRepeat = ref<string>('')
    const showToast = ref<boolean>(false)
    const toastType = ref<'info'|'error'>('info')
    const toastMessage = ref<string>('')
    const router = useRouter()

    const registerUser = async () => {
      if (newUser.value.username === '' || newUser.value.email === '' || newUser.value.password === '') {
        newUser.value.error = true
        newUser.value.errorMsg = 'Bitte fülle alle Felder aus!'
      } else {
        if (passwordRepeat.value === newUser.value.password) {
          UserService.createUser(newUser.value)
              .then(response => {
                if (response) {
                  showToast.value = true
                  toastMessage.value = 'Benutzer erfolgreich angelegt! Du wirst gleich weitergleitet'
                  setTimeout(() => {
                    router.push('/gaesteprofil')
                  }, 2500)
                } else {
                  toastType.value = "error"
                  toastMessage.value = "Der Benutzer konnte nicht angelegt werden. Überprüfe deine Eingabe!"
                  showToast.value = true
                }
              })
          .catch(exp => {
            toastType.value = "error"
            toastMessage.value = "Der Benutzer konnte nicht angelegt werden. Überprüfe Email und Passwort! " + exp.message
            showToast.value = true
          })
        } else {
          newUser.value.error = true
          newUser.value.errorMsg = 'Passwörter müssen übereinstimmen!'
        }
      }
    }
    const handleToast = () => {
      showToast.value = !showToast.value
    }

    onMounted(() => {
      const token = LocalStorageHelper.getItem('token')
      if (token) {
        router.push('/gaesteprofil')
      }
    })

    return {newUser, passwordRepeat, showToast, toastMessage, toastType, handleToast, registerUser}
  }
})
